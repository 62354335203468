<template>
  <transition name='fade-up'>
    <div class='bg-blue-500 fixed min-h-16 bottom-0 w-full z-20 shadow-lg pb-4 pr-1' v-if='gdprConsent !== "granted"'>
      <div class='container container-slim py-2 text-white text-md text-center px-2'>
        <p class='pb-2'>
          Minafi use cookies &amp; emails to ensure the best experience possible.
          <span class="hidden md:inline">
            If you're obsessed with security, check out our
            <a class='link-blue-inverse' href='/privacy-policy'>privacy policy</a>
            before agreeing.
          </span>
          <span class="md:hidden">
            Check out our <a class='link-blue-inverse' href='/privacy-policy'>privacy policy</a>
            for more.
          </span>
        </p>

        <p class='text-center'>
          <button class='button-block button-blue-darker border-blue-700 button border-4 hover:border-blue-700' @click.prevent='gdprConsentGranted' aria-label='I agree'>
            I agree to Minafi's Privacy Policy <span class='hidden md:inline'>(like every other site)</span>
          </button>
        </p>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  computed: {
    ...mapFields({
      gdprConsent: 'user.gdprConsent'
    })
  },
  methods: {
    gdprConsentGranted() {
      this.$store.dispatch('user/gdprConsent')
    }
  }
}
</script>
