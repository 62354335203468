import { createStore } from 'vuex'
import { getField } from 'vuex-map-fields'

import app from './modules/app'
import comments from './modules/comments'
import interactive from './modules/interactive'
import post from './modules/post'
import user from './modules/user'
import userCourse from './modules/user-course'

const store = createStore({
  getters: {
    getField
  },
  modules: {
    app,
    comments,
    interactive,
    post,
    user,
    userCourse
  }
})

// Make the store globally available for use outside of components
window.store = store
export default store
