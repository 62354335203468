<template>
  <div v-show='showModal' @click.self="closeModal" class="fixed z-100 inset-0 overflow-auto bg-smoke-700 flex">
    <transition class="fixed shadow-inner max-w-md md:relative bottom-0 inset-x-0 align-top m-auto justify-end md:justify-center p-8 bg-white md:rounded w-full md:h-auto md:shadow flex flex-col" name="fade-up">
      <div v-if='showModal'>
        <h2 class="text-4xl font-bold text-center md:leading-loose text-grey-700 md:mt-4 mb-2">Join Minafi</h2>
        <p class='text-grey-700 text-xl font-light text-center'>Create an account <b>for free</b> and you'll get:</p>

        <ul class='text-lg mt-4 text-left mx-auto inline-block'>
          <li class='mb-1'>✓ Learn to invest confidently for <b>free</b>.</li>
          <li class='mb-1'>✓ <a href='/courses/the-minimal-investor' class='link link-blue'>The Minimal Investor</a> <span class='hidden md:inline'>course </span>access.</li>
          <li class='mb-1'>✓ Save &amp; Track Progress <span class='hidden md:inline'>as you </span>learn!</li>
        </ul>

        <div class='flex flex-col mt-8 text-lg mx-auto'>
          <a v-bind:href="returnUrl('/users/sign_up')" class='py-2 px-4 mb-4 flex items-center max-w-xs bg-green-500 hover:bg-green-700 text-white font-bold rounded-lg no-underline' rel='noindex'>
            <minicon icon='minafi-logo' size="6" class='mr-4'></minicon>
            <span>Create a free Minafi Account</span>
          </a>
          <a v-bind:href="returnUrl('/users/auth/facebook')" class='py-2 px-4 flex items-center mb-4 max-w-xs bg-facebook hover:bg-facebook-dark text-white font-bold rounded-lg no-underline' rel='noindex'>
            <minicon icon='facebook-square-brands' size="8" class='mr-2'></minicon>
            <span>Continue with Facebook</span>
          </a>
          <a v-bind:href="returnUrl('/users/auth/twitter')" class='py-2 px-4 flex items-center max-w-xs bg-twitter hover:bg-twitter-dark text-white font-bold rounded-lg no-underline' rel='noindex'>
            <minicon icon='twitter-brands' size="8" class='mr-2'></minicon>
            <span>Continue with Twitter</span>
          </a>
        </div>

        <a @click.prevent="closeModal" class="absolute top-0 right-0 mt-4 mr-4 hover:bg-blue-100 p-2 pl-4 rounded" href='#'>
          <minicon icon='times-regular' size="6"></minicon>
        </a>
      </div>
    </transition>
  </div>
</template>


<script>
import { mapFields } from 'vuex-map-fields'
import Minicon from 'Components/utils/minicon.vue'

export default {
  components: {
    Minicon
  },
  computed: {
    ...mapFields({
      showModal: 'app.showLogin'
    })
  },
  methods: {
    closeModal() {
      this.$store.dispatch('app/hideLogin')
    },
    returnUrl(url) {
      return url+'?redirect_to='+window.location
    }
  }
}
</script>
