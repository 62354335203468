<template>
  <div class='shadow-lg sidebar border-green-500 px-8 py-8 bg-gradient-to-b from-white via-white to-green-100' v-if='shouldShow'>
    <div class='text-center'>
      <p class='text-center'><minicon icon='envelope-regular' size='24' class="text-green-500"></minicon></p>

      <p class='reset text-4xl font-bold mb-6'>The Minafi Magazine</p>
      <p class='reset text-grey-700 text-lg'>
        A dose of financial advice, minimalism and mindfulness <b>delivered just for you</b>.
      </p>

      <transition name='fade-up'>
        <div>
          <div class='form-content mt-6' v-if='!this.submitted'>
            <form @submit.prevent='submit'>
              <div class='field mb-6'>
                <label for='form-minafi-magazine-email' class='sr-only'>Email</label>
                <input id='form-minafi-magazine-email' type='email' placeholder="What's your email?" class='form-text' v-model='email' :class="{ 'border-red': errors.email }" />
                <p class='text-red-500 text-base text-left mt-1' v-if='errors.email'>{{errors.email}}</p>
              </div>
              <div class='field mb-6'>
                <label for='form-minafi-magazine-name' class='sr-only'>Name</label>
                <input id='form-minafi-magazine-name' type='text' class='form-text' placeholder="What's your name?" v-model='name' :class="{ 'border-red': errors.name }"/>
                <p class='text-red-500 text-base text-left mt-1' v-if='errors.name'>{{errors.name}}</p>
              </div>
              <div class='field flex content-center flex-row ml-1'>
                <input type='checkbox' id='gdpr_consent' value='granted' v-model='gdpr_consent' class='mr-2 p-2 leading-tight transform scale-150 self-center mb-2' />
                <label for='gdpr_consent' class='form-label ml-2 text-left' v-html='gdpr_consent_message'></label>
              </div>
              <p class='text-red-500 text-base text-left mt-1 mb-2' v-if='errors.consent'>{{errors.consent}}</p>
              <div class='mt-6 mb-2'>
                <button type='submit' class='button button-green button-flex justify-center items-center' aria-label='Subscribe'>
                  <minicon v-if='submitting' icon='spinner-regular' size='6' class="text-white spin inline-block flex-shrink"></minicon>
                  <minicon v-else icon='envelope-regular' size='6' class="text-white inline-block flex-shrink"></minicon>
                  <span class='flex-shrink ml-2 text-xl'>Subscribe</span>
                </button>
              </div>
            </form>
          </div>

          <div class='submitted-confirmation' v-if='this.submitted'>
            <div class='adam-head bg-green-100 mt-8 py-4 px-2 rounded'>
              <p class='reset text-grey-800 mb-2 text-xl'>Welcome to the Minafi Community!</p>
              <p class='reset text-grey-700 text-lg text-center'>
                Confirm your email and I'll send you a few of my best articles of all time.<br/><br/>
                If you have any questions or comments, just reply to any email. - Adam
              </p>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import * as client from 'App/client'
import Minicon from 'Components/utils/minicon.vue'
import { mapFields } from 'vuex-map-fields'

export default {
  props: ['show'],
  components: {
    Minicon
  },
  computed: {
    ...mapFields({
      'subscriptions': 'user.subscriptions',
      'user': 'user'
    }),
    completed() {
      return this.subscriptions.indexOf('newsletter') !== -1
    },
    shouldShow() {
      if(this.completed) { return false }
      return this.show || this.user.guest
    }
  },
  data() {
    return {
      email: '',
      name: '',
      gdpr_consent: false,
      gdpr_consent_message: "I consent to receive information via email. (Read the <a href='/privacy-policy'>privacy policy</a> for more information).",
      submitting: false,
      submitted: false,
      errors: {
        name: null,
        email: null,
        consent: null
      }
    }
  },
  mounted() {
    if(this.user && !this.user.guest) {
      this.name = this.user.name
    }
  },
  methods: {
    submit() {
      if(!this.valid()) { return; }

      // Magic to submit this
      this.submitting = true
      this.$el.classList.add('form-submitting')

      client.post('/account/subscribe', {
        subscription: 'newsletter',
        fields: {
          email: this.email,
          name: this.name,
          gdpr: this.gdpr_consent,
          consent_message: this.gdpr_consent_message
        }
      }).then((response) => {
        this.submitting = false
        if(response.message) {
          this.errors.consent = "Uh oh! There was a problem. Could you give it another try?"
        } else {
          this.submitted = true
          this.$el.classList.remove('form-submitting')
          this.$el.classList.add('form-submitted')
        }
      })
    },
    valid() {
      if(!this.name) {
        this.errors.name = 'Please enter a user name.'
      } else {
        this.errors.name = null
      }

      if(!this.validEmail(this.email)) {
        this.errors.email = 'Please enter a valid email address.'
      } else {
        this.errors.email = null
      }

      if(!this.gdpr_consent) {
        this.errors.consent = 'You really need to agree to these terms. Legal people love them.'
      } else {
        this.errors.consent = null
      }

      return !(this.errors.email ||  this.errors.name ||  this.errors.consent)
    },
    validEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
  }
}
</script>
