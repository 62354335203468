import { put } from 'App/client'
import { throttle } from 'App/utils/throttle'
import { isEqual } from 'App/utils/isEqual'
import { cloneDeep } from 'App/utils/cloneDeep'

let lastPayload = {}

let updateServerState = throttle((payload) => {
  put('/interactive', {
    guide: payload.guide,
    namespace: payload.namespace
  })
}, 5000, { trailing: true })

// state
const state = window.data.interactive ? cloneDeep(window.data.interactive) : { }


// getters
const getters = {
  getGuideField: (state) => (namespace, field) => {
    return (state[namespace] || {})[field]
  },
  getGuideFields: (state) => (namespace) => {
    return state[namespace] || {}
  }
}


// actions
const actions = {
  setGuideField({commit}, payload) {
    commit('SET_GUIDE_FIELD', payload)
  },
  setGuideFields({commit}, payload) {
    var differentNamespace = (lastPayload.namespace != payload.namespace),
        namespacedChange = (!lastPayload[lastPayload.namespace] || !isEqual(lastPayload.guide, payload.guide))
    if(differentNamespace || namespacedChange) {
      lastPayload[lastPayload.namespace] = cloneDeep(payload)
      commit('SET_GUIDE_FIELDS', payload)
      if(!payload.guide.temp) {
        updateServerState(payload)
      }
    }
  }
}


// mutations
const mutations = {
  SET_GUIDE_FIELD(state, {namespace, field, value}) {
    state[namespace][field] = value
  },
  SET_GUIDE_FIELDS(state, {namespace, guide}) {
    state[namespace] = guide
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
