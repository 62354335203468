import { post, put } from 'App/client'
import { getCookie } from 'App/cookie'

let activity = JSON.parse(getCookie('post_activity') || "{}")

const state = {
  post: window.data.post || {},
  activity: activity,
  action: 'initialized',
  scroll: 0
}

// getters
const getters = {
}

// actions
const actions = {
  progress({commit, state}, {progress}) {
    commit('SET_ACTION', 'completed')

    // Skip if it's already set to this progress amount.
    if(state.activity.progress == progress) { return }

    // Skip any nested paths
    var slugs = window.location.pathname.replace("/", "").split("/"),
        slug = slugs[slugs.length-1],
        courseSlug;

    if((slugs.length > 1) && (slugs[0] == 'courses')) {
      courseSlug = slugs[1];
    }

    if(slug && slug.length > 0) {
      return post('/account/history', {
        trackable_type: 'Entity',
        trackable_slug: slug,
        course: courseSlug,
        progress: progress
      }).then((response) => {
        commit('SET_ACTIVITY', response)
        return response
      })
    }
  },

  task({commit, state}, {task, complete}) {
    return put(`/account/activities/${state.activity.id}`, {
      task: task,
      complete: complete
    }).then((response) => {
      commit('SET_ACTIVITY', response)
      return response
    })
  },

  started({commit}) {
    commit('SET_ACTION', 'started')
  },

  scroll({commit}, scrollPercent) {
    commit('SET_SCROLL', scrollPercent)
  }
}

// mutations
const mutations = {
  SET_ACTIVITY (state, activity) {
    state.activity = activity
  },
  SET_ACTION (state, action) {
    state.action = action
  },
  SET_POST (state, post) {
    state.post = post
  },
  SET_SCROLL (state, scrollPercent) {
    state.scroll = scrollPercent
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
