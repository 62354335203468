<template>
  <i :class="`icon-${icon} w-${size} text-${size} inline-block`"></i>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true
    },
    size: {
      type: String,
      required: true
    },
  }
}
</script>
