import { get } from 'App/client'

const state = {
  course: {
    lessons: [],
    name: '',
    url: '',
    progress: 0
  }
}

// actions
const actions = {
  load({commit}, courseSlug) {
    return get(`/users/courses/${courseSlug}.json`)
      .then((response) => commit('SET_COURSE', response.course))
  },

  started({commit}) {
    commit('post/setAction', 'started')
  }
}

// mutations
const mutations = {
  SET_COURSE(state, course) {
    this.state.userCourse.course.lessons = course.lessons
    this.state.userCourse.course.name = course.name
    this.state.userCourse.course.short_description = course.short_description
    this.state.userCourse.course.url = course.url
    this.state.userCourse.course.progress = course.progress
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
