// state
const state = {
  showLogin: false,
  sidebarExpanded: false
}

// actions
const actions = {
  showLogin({commit}) {
    commit('SET_MODAL_LOGIN', true)
  },
  hideLogin({commit}, comment) {
    commit('SET_MODAL_LOGIN', false)
  },

  toggleSidebar({commit}) {
    commit('SET_SIDEBAR', 'toggle')
  },
  expandSidebar({commit}) {
    commit('SET_SIDEBAR', true)
  },
  collapseSidebar({commit}) {
    commit('SET_SIDEBAR', false)
  }
}


// mutations
const mutations = {
  SET_MODAL_LOGIN (state, active) {
    state.showLogin = active
  },
  SET_SIDEBAR (state, open) {
    if(open == 'toggle') {
      state.sidebarExpanded = !state.sidebarExpanded
    } else {
      state.sidebarExpanded = open
    }

    var classToAdd = state.sidebarExpanded ? 'side-nav--expanded' : 'side-nav--collapsed',
        classToRemove = state.sidebarExpanded ? 'side-nav--collapsed' : 'side-nav--expanded'
    document.body.classList.remove(classToRemove)
    document.body.classList.add(classToAdd)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
