export function request(req) {
  const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

  const options = {
    method: req['method'] || 'GET',
    headers: {
      'X-CSRF-Token': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }

  if(req['body']) {
    options['body'] = JSON.stringify(req['body'])
  }

  return fetch(req.url, options)
    .then(response => response.text().then(function(text) {
      return text ? JSON.parse(text) : {}
    }))
}

export function get(endpoint) {
  return request({
    url: endpoint,
    method: 'GET'
  })
}

export function remove(endpoint) {
  return request({
    url: endpoint,
    method: 'DELETE'
  })
}

export function post(endpoint, data) {
  return request({
    url: endpoint,
    method: 'POST',
    body: data
  })
}

export function put(endpoint, data) {
  return request({
    url: endpoint,
    method: 'PUT',
    body: data
  })
}
